html,
body {
  background-color: #000;
  font-size: 16px;
}

.App {
  color: white;
  font-family: 'Times New Roman', Times, serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.first-section {
  height: 100vh;
  width: 100vw;
  display: flex;
  padding: 24px;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
}

.inner-stroke {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 8px;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  padding: 36px;
  padding-bottom: 96px;
}

.book-title {
  font-size: 1.8em;
  flex: 1;
  display: flex;
  padding: 20px;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  padding-bottom: 56px;
}

.book-author,
.book-subtitle,
.website-date {
  font-size: 1.2em;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
}

.second-section {
  padding: 32px;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}

.prologue-title {
  font-size: 1.8em;
  line-height: 1.2em;
}

.prologue-subtitle {
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 700;
}

.prologue-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.prologue-content>p {
  font-size: 1em;
  line-height: 1.4em;
  text-align: justify;
}